<!-- 第三方应用以及土增的内嵌入口 -->
<template>
  <div
    class="main-wrapper"
    v-loading="!loaded"
    element-loading-text="正在验证授权"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <router-view v-if="isAuthored" />
  </div>
</template>

<script>
import { getPreInspectResult } from "@/api/oem";
import { getProvince, authAESEncrypt, authAESDecrypt } from "@/api/api";

export default {
  name: "OemGuard",
  props: {
    accessToken: String,
    uuid: String,
  },
  data() {
    return {
      loaded: false,
      isAuthored: false,
    };
  },
  components: {},
  async mounted() {
    if (this.$route.query.fromHouTai == "houtai") {
      localStorage.setItem("authorization", this.$route.query.token);
    }
    //预检测
    const res = await getPreInspectResult({
      accessToken: this.accessToken,
      preInspectId: this.uuid,
    });

    if (res.code == 200) {
      let params = {
        taxpayerId: res.data.companyTaxNo,
      };
      // try {
      //   const provinceRes = await getProvince(params, "daqc");
      //   res.data = {
      //     ...res.data,
      //     province: provinceRes.data.province,
      //   };
      // } catch (e) {
      //   console.error(e);
      // }

      if (this.$route.query.from == "lvat") {
        let params = {
          companyName: res.data.companyName,
          programId: res.data.preInspectId,
          taxNo: res.data.companyTaxNo,
          zq: this.$route.params.zq,
          channelCode: "lvat",
          companyNo: res.data.companyNo,
        };
        // 加密出土增的分享链接
        let resAes = await authAESEncrypt(params);
        let loginMsg = resAes.data;
        let loginUrl =
          process.env.VUE_APP_PDF_ROOT_FETCH +
          "oemV3/auth" +
          "?key=" +
          encodeURIComponent(resAes.data) + 
          "&projectId=" + this.$route.query.projectId;
        res.data.loginMsg = loginMsg;
        res.data.loginUrl = loginUrl;
      }

      this.$store.commit("oem/setAuth", res.data);
      this.$store.commit("oem/setZq", this.$route.params.zq);

      this.loaded = true;
      this.isAuthored = true;
    } else {
      this.$alert("授权认证失败,请联系管理员", "消息", {
        confirmButtonText: "",
        showConfirmButton: false,
        showClose: false,
        callback: (action) => {},
      });
    }
  },
};
</script>
<style lang="stylus" scoped>
.main-wrapper {
  // min-height 100vh
}
</style>
